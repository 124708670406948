import Logo from './logo.png';
import React, {Component, useEffect, useState} from 'react';
import axios from "axios";
import { Routes, Route, useParams } from 'react-router-dom';
import $ from "jquery"
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Header from "./Components/Header";
import BannerDesigns from "./Components/BannerDesigns";

const Product = () => {

    const [productData, setproductData] = useState(0);
    const [open, setOpen] = React.useState(false);

    let { productId } = useParams();
    useEffect(() => {
        axios.get(`get-product/${productId}`)
            .then(response => {
                const product = response.data;
                setproductData(product)
                console.log(product);
            })
    }, []);
    

    const getImage = (image) => {
        return axios.defaults.baseURL + "get_image/?image_name=" + image
    };

    const getImageList = (images) => {
        if(images !== undefined)
        {
            let lightboximages = [];
            images.forEach((p, index) => {
                lightboximages.push({src: getImage(p.Filename)})
            });
            return lightboximages;
        }

    };
    
    const startLightBox = (e) =>
    {
        e.preventDefault();
        setOpen(true);
    };

    const viewProdOnWebsite = (e, URL, brandId) =>
    {
        e.preventDefault();
        if (brandId === 1)
        {
            let website = "https://nutriflair.com";
            website = website + URL;
            window.open(website, '_blank');
        }
        else if (brandId === 2)
        {
            let website = "https://doublewoodsupplements.com";
            website = website + URL;
            window.open(website, '_blank');
        }
        else if (brandId === 3)
        {
            let website = "https://www.evlnutrition.com";
            website = website + URL;
            window.open(website, '_blank');
        }

    };

    const getMainImage = (images) => {
        if(images !== undefined)
        {
            let image = '';
            images.forEach((p, index) => {
                if(p.IsMain === 1)
                {
                    image = getImage(p.Filename)
                }
            });
            return image;
        }

    };


    return (
        <>
            <Header />

 


            <section className="section pb-0" style={{ marginBottom: '130px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-12 row noPrint" style={{padding: '13px 30px'}}>
                            <h3>{productData.Name}</h3>
                        </div>
                        <div className="col-lg-12 mb-12">
                            <h2 className="h5 ">
                                <a className="" href="/home">
                                    <i className="ti-angle-left"></i> Back
                                </a>

                            </h2>
                            <article className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4 mb-12">

                                            <article className="card mb-4">
                                                <div className="post-slider">
                                                    <img src={getMainImage(productData.ProductImages)}
                                                         onClick={(e) => startLightBox(e)} className="card-img-top"
                                                         alt="post-thumb"/>
                                                </div>

                                                <div className="card-body" style={{textAlign: 'center'}}>
                                                    <a href="#" onClick={(e) => startLightBox(e)}
                                                       className="btn btn-outline-primary">View All Images</a>
                                                </div>
                                            </article>

                                        </div>
                                        <div className="col-lg-8 mb-12" style={{fontSize: '19px'}}>
                                            {productData.Description}
                                            <p style={{borderBottom: '1px #ccc solid', marginTop: '10px'}}></p>
                                            <p style={{fontSize: '16px'}}>Price : {productData.ActualPrice}</p>
                                            <p style={{fontSize: '16px'}}>Sale Price : {productData.SalePrice}</p>
                                            <p style={{fontSize: '16px'}}>Ingredients : {productData.Ingredients}</p>

                                            <div className="card-body" style={{padding: '25px 0px'}}>
                                                <a href="#"
                                                   onClick={(e) => viewProdOnWebsite(e, productData.Url, productData.BrandId)}
                                                   className="btn btn-outline-primary">View Product On Website</a>
                                            </div>

                                            <div className="col-lg-12 mb-12">
                                                <section className="section">
                                                    <div className="py-4"></div>
                                                    <div className="container">
                                                        <div className="row">
                                                            <Lightbox
                                                                open={open}
                                                                close={() => setOpen(false)}
                                                                slides={getImageList(productData.ProductImages)}
                                                            />

                                                        </div>

                                                    </div>
                                                </section>
                                            </div>
                                        </div>


                                        <div className="col-lg-12 mb-12">
                                            <div className="widget widget-categories">
                                                <div className="row">
                                                    {productData.Modatas?.map(p => (
                                                        <div className="col-lg-6 mb-12">
                                                            <ul className="list-unstyled widget-list">
                                                                <li><a href="#" className="d-flex">Type <small
                                                                    className="ml-auto">{p.Type}</small></a></li>
                                                                <li><a href="#" className="d-flex">Item <small
                                                                    className="ml-auto">{p.Item}</small></a></li>
                                                                <li><a href="#" className="d-flex">Qty <small
                                                                    className="ml-auto">{p.Qty}</small></a></li>
                                                                <li><a href="#" className="d-flex">Unit Price <small
                                                                    className="ml-auto">{p.Unit_Price}</small></a></li>
                                                                <li><a href="#" className="d-flex">Column5 <small
                                                                    className="ml-auto">{p.column5}</small></a></li>
                                                                <li><a href="#" className="d-flex">Material Unit
                                                                    Cost <small
                                                                        className="ml-auto">{p.Material_Unit_Cost}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Material Cost <small
                                                                    className="ml-auto">{p.Material_Cost}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Deposit <small
                                                                    className="ml-auto">{p.Deposit}</small></a></li>
                                                                <li><a href="#" className="d-flex">Deposit1 <small
                                                                    className="ml-auto">{p.Deposit1}</small></a></li>
                                                                <li><a href="#" className="d-flex">Final Invoice <small
                                                                    className="ml-auto">{p.Final_Invoice}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">PO Receive
                                                                    Date <small
                                                                        className="ml-auto">{p.PO_Receive_Date}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Target Deposit
                                                                    Date <small
                                                                        className="ml-auto">{p.Target_Deposit_Date}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Dep Paid <small
                                                                    className="ml-auto">{p.Dep_Paid}</small></a></li>
                                                                <li><a href="#" className="d-flex">RFQ <small
                                                                    className="ml-auto">{p.RFQ}</small></a></li>
                                                                <li><a href="#" className="d-flex">RFQ Status <small
                                                                    className="ml-auto">{p.RFQ_Status}</small></a></li>
                                                                <li><a href="#" className="d-flex">Production
                                                                    Quantity <small
                                                                        className="ml-auto">{p.Production_Quantity}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Confidence L M
                                                                    H <small
                                                                        className="ml-auto">{p.Confidence_L_M_H}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">CO Entered
                                                                    Date <small
                                                                        className="ml-auto">{p.CO_Entered_Date}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">CO Rec'd Date <small
                                                                    className="ml-auto">{p.CO_Rec_d_Date}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Start Date <small
                                                                    className="ml-auto">{p.Start_Date}</small></a></li>
                                                                <li><a href="#" className="d-flex">Sourcing Rep <small
                                                                    className="ml-auto">{p.Sourcing_Rep}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Finished <small
                                                                    className="ml-auto">{p.Finished}</small></a></li>
                                                                <li><a href="#" className="d-flex">Notes <small
                                                                    className="ml-auto">{p.NOTES}</small></a></li>
                                                                <li><a href="#" className="d-flex">SO <small
                                                                    className="ml-auto">{p.SO}</small></a></li>
                                                                <li><a href="#" className="d-flex">PO <small
                                                                    className="ml-auto">{p.PO}</small></a></li>
                                                                <li><a href="#" className="d-flex">Customer Code <small
                                                                    className="ml-auto">{p.Customer_Code}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Sales Rep <small
                                                                    className="ml-auto">{p.Sales_Rep}</small></a></li>
                                                                <li><a href="#" className="d-flex">Production
                                                                    Type <small
                                                                        className="ml-auto">{p.Production_Type}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Production Start
                                                                    Date <small
                                                                        className="ml-auto">{p.Production_Start_Date}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Target Completion
                                                                    Date <small
                                                                        className="ml-auto">{p.Target_Completion_Date}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Raw Material
                                                                    Status <small
                                                                        className="ml-auto">{p.Raw_Material_Status}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Packaging
                                                                    Status <small
                                                                        className="ml-auto">{p.Packaging_Status}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Labels Status <small
                                                                    className="ml-auto">{p.Labels_Status}</small></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">CSM Status <small
                                                                    className="ml-auto">{p.CSM_Status}</small></a></li>

                                                            </ul>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </article>

                        </div>


                        <div className="col-12">
                            <div className="border-bottom border-default"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}

export default Product;
