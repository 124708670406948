import React, { Component }  from 'react';
import axios from "axios";
import $ from "jquery";
import Header from "./Components/Header";
import BannerDesigns from "./Components/BannerDesigns";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import PaginatedItems from "./Components/Pagination";

export class Home extends Component{
    state = {
        brands: [],
        products:[],
        selectBrand: {},
        fromDate : new Date("January 01, 2023 01:00:01"),
        toDate : new Date("September 15, 2023 01:00:01"),
        nfTotalProds : 0,
        totalQuotes:0,
        uniqueProdQuoted:0,
        prodsNotQuoted:0,
        shareOfWalletQuoted:0,
        won:0,
        winPercOfQuoted:0,
        lost:0,
        lostPercOfQuoted:0,
        pending:0,
        pendingPercOfQuoted:0,
        pageNumber:1,
        search: '',
        status: '',
        formType: ''
    }

    getProductsByBrand(brand, pageNumber, fromDate, toDate, status, search, formType) {

        if(fromDate === undefined) {
            fromDate = new Date("January 01, 2023 01:00:01");
        }
        if(toDate === undefined) {
            toDate = new Date("September 15, 2023 01:00:01");
        }
        if(brand === undefined) {
            brand = "";
        }
        if(status === undefined) {
            status = "";
        }
        if(search === undefined) {
            search = "";
        }
        if(formType === undefined) {
            formType = "";
        }

        let fromDate_iso = fromDate.toISOString();
        let toDate_iso = toDate.toISOString();
        let fromDate_string = fromDate_iso.split('T')[0];
        let toDate_string = toDate_iso.split('T')[0];
        let dataObj = {
            start_datetime:  fromDate_string,
            end_datetime: toDate_string,
            brandId: brand.brandId,
            search: search,
            page:pageNumber,
            status: status,
            formType:formType
        }
        console.log(dataObj)
        axios.put(`get-products`, dataObj)
            .then(response => {
                const products= response.data.results;
                const nfTotalProds = response.data.nfTotalProds;
                const totalQuotes = response.data.totalQuotes;
                const uniqueProdQuoted = response.data.uniqueProdQuoted;
                const prodsNotQuoted = response.data.prodsNotQuoted;
                const shareOfWalletQuoted = response.data.shareOfWalletQuoted;
                const won = response.data.won;
                const winPercOfQuoted = response.data.winPercOfQuoted;
                const lost = response.data.lost;
                const lostPercOfQuoted = response.data.lostPercOfQuoted;
                const pending = response.data.pending;
                const pendingPercOfQuoted = response.data.pendingPercOfQuoted;
                
                this.setState({ 
                    products, 
                    nfTotalProds,
                    totalQuotes,
                    uniqueProdQuoted,
                    prodsNotQuoted,
                    shareOfWalletQuoted,
                    won,
                    winPercOfQuoted,
                    lost,
                    lostPercOfQuoted,
                    pending,
                    pendingPercOfQuoted
                });
            })
    }
    getPrice(product)
    {
        if(product.SalePrice === '0' && product.ActualPrice) {
            return product.ActualPrice
        }
        if(product.SalePrice) {
            return product.SalePrice
        }

    }

    openPage(e, productId){
        e.preventDefault();
        window.location.href = "/product/"+productId;
    }
    
    getLastQuote(quotes, key)
    {
        if(quotes !== null && quotes.length > 0)
        {
            //let last =  quotes[quotes.length - 1];
            return quotes[0][key];
        }
    }
    
    setElementId(id, type)
    {
        return 'prod' + id + "type" + type;
    }
    
    setFromDate(e)
    {
        console.log(e);
        const fromDate = new Date(e);
        this.setState({ fromDate });
        this.getProductsByBrand(this.state.selectBrand, this.state.pageNumber, fromDate, this.state.toDate, this.state.status, this.state.search, this.state.formType)
    }

    setToDate(e)
    {
        console.log(e);
        const toDate = new Date(e);
        this.setState({ toDate });
        this.getProductsByBrand(this.state.selectBrand, this.state.pageNumber, this.state.fromDate, toDate, this.state.status, this.state.search, this.state.formType)
    }


    paginatePage = (page)=> {
        console.log(`pageNumber ${page}`)
        this.setState({ pageNumber : page });
        this.getProductsByBrand(this.state.selectBrand, page, this.state.fromDate, this.state.toDate, this.state.status, this.state.search, this.state.formType)
    }


    
    setValuesByMo(e, mo, productId, Modatas)
    {
        e.preventDefault();
        console.log(mo);
        $('#' + this.setElementId(productId, 'rfq')).text(mo['RFQ']);
        $('#' + this.setElementId(productId, 'qty')).text(mo['Qty']);
        $('#' + this.setElementId(productId, 'unitPrice')).text(mo['Unit_Price']);

        Modatas.forEach((modat, index) => {
            $('#' + this.setElementId(modat.Id, 'activMo')).removeClass("activeStatus");
            if(modat.Id === mo.Id)
            {
                $('#' + this.setElementId(mo.Id, 'activMo')).addClass("activeStatus")
            }
        });
        
    }
    
    componentDidMount() {
        if (localStorage.getItem("loc_brand") !== null && localStorage.getItem("loc_brand") !== undefined) {
            let lsValue = JSON.parse(localStorage.getItem("loc_brand"));
            if (lsValue.hasOwnProperty("brandId")) {
                const selectBrand = lsValue;
                this.setState({ selectBrand });
                this.getProductsByBrand(lsValue, this.state.pageNumber,this.state.fromDate, this.state.toDate, this.state.status, this.state.search, this.state.formType);
                console.log('in LS')
            }
        }
        let _self = this;
        axios.get(`get-brands`)
            .then(response => {
                const brands = response.data;
                _self.setState({ brands });
                if(Object.keys(brands).length > 0)
                {
                    if (!_self.state.selectBrand.hasOwnProperty("brandId")) {
                        const selectBrand = brands[0].Brand;
                        _self.setState({ selectBrand });
                        _self.getProductsByBrand(brands[0].Brand, _self.state.pageNumber,this.state.fromDate, this.state.toDate, this.state.status, this.state.search, this.state.formType);
                    }

                }
            })
    }

    setActiveTab(e, brand) {
        const products = [];
        this.setState({ products });
        
        this.state.selectBrand = brand;
        this.getProductsByBrand(brand, this.state.pageNumber);
        localStorage.setItem('loc_brand', JSON.stringify(brand))
        console.log(brand);
    }

    getImage(images)  {
        let img = "";
        if(images.length > 0)
        {
            img = axios.defaults.baseURL + "get_image/?image_name=" + images[0].Filename;
            images.forEach((image, index) => {
                if(image.IsMain === 1)
                {
                    img = axios.defaults.baseURL + "get_image/?image_name=" + image.Filename;
                }
            })
            return img;
        }

    }

    onSearchClick(e)
    {
        console.log(this.state.search)
        this.getProductsByBrand(this.state.selectBrand, this.state.pageNumber, this.state.fromDate, this.state.toDate, this.state.status, this.state.search, this.state.formType)
    }

    onSearch(e)
    {
        this.setState({ search: e.target.value })
    }

    onKeyUp (e)
    {
        if (e.charCode === 13) {
            this.onSearchClick(e);
        }
    };

    selectStatus(e)
    {
        this.setState({ status: e.target.value })
        this.getProductsByBrand(this.state.selectBrand, this.state.pageNumber, this.state.fromDate, this.state.toDate, e.target.value, this.state.search, this.state.formType)
    }

    selectFormType(e)
    {
        this.setState({ formType: e.target.value })
        this.getProductsByBrand(this.state.selectBrand, this.state.pageNumber, this.state.fromDate, this.state.toDate, this.state.status, this.state.search, e.target.value)
    }
    
    render() {
        return (
            <>
                <Header  />

                <div className="banner text-center">
                    <div className="container">
                        <div className="row" id="banner">
                            <div className="col-lg-9 mx-auto">
                                <h1 className="mb-5">What Brand Would You <br/> Like To Review?</h1>
                                <ul className="list-inline widget-list-inline">
                                    {this.state.brands.map(brand => <li className="list-inline-item" ><a onClick={(event) => {this.setActiveTab(event, brand.Brand)}} className={`${(this.state.selectBrand.hasOwnProperty('brandId') && this.state.selectBrand.brandId === brand.Brand.brandId) ? 'selectedBrand' : '' }`} href="#" >{brand.Brand.Name}</a></li> )}

                                </ul>
                            </div>
                        </div>
                    </div>
                    <BannerDesigns/>
                </div>


                <section className="section pb-0" style={{ marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12 row" >

                                <div className="input-group col-lg-3 mb-3" style={{display:'flex', justifyContent:"center", alignItems:"center"}}>
                                    <div className="input-group-prepend" style={{height:'31px'}}>
                                        <label className="input-group-text" htmlFor="inputGroupSelect01">From</label>
                                    </div>
                                    <DatePicker selected={this.state.fromDate} class="form-control-date" onChange={(e) => this.setFromDate(e)} />
                                </div>

                                <div className="input-group col-lg-3 mb-3" style={{display:'flex', justifyContent:"center", alignItems:"center"}}>
                                    <div className="input-group-prepend" style={{height:'31px'}}>
                                        <label className="input-group-text" htmlFor="inputGroupSelect01">To</label>
                                    </div>
                                    <DatePicker selected={this.state.toDate} class="form-control-date" onChange={(e) => this.setToDate(e)} />
                                </div>

                                <div className="input-group col-lg-3 mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text" htmlFor="inputGroupSelect01">Status</label>
                                    </div>
                                    <select className="custom-select" id="inputGroupSelect01" onChange={(e) => this.selectStatus(e)}>
                                        <option selected value="">All</option>
                                        <option value="1">Won</option>
                                        <option value="2">Lost</option>
                                        <option value="3">Pending</option>
                                        <option value="4">Not Quoted</option>
                                    </select>
                                </div>

                                <div className="input-group col-lg-3 mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text" htmlFor="inputGroupSelect01">Form Type</label>
                                    </div>
                                    <select className="custom-select" id="inputGroupSelect01" onChange={(e) => this.selectFormType(e)}>
                                        <option selected value="">All</option>
                                        <option value="1">Liquid</option>
                                        <option value="2">Powder</option>
                                        <option value="3">Tablet</option>
                                        <option value="4">Capsule</option>
                                    </select>
                                </div>



                            </div>
                            <div className="col-lg-12 mb-12 row" >
                                <div className="input-group col-lg-7 mb-3">

                                </div>
                                <div className="input-group col-lg-5 mb-3">
                                    <input onKeyPress={(e) => this.onKeyUp(e)} type="text" className="form-control" onChange={(e) => this.onSearch(e)} placeholder="Product Search" aria-label="search" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <span onClick={(e) => this.onSearchClick(e)} className="input-group-text ti-search" id="basic-addon2"></span>
                                    </div>
                                </div>
                            </div>



                            
                            <div className="col-lg-12 mb-12">
                                <div className="widget widget-categories">
                                    <div className="row">
                                        <div className="col-lg-4 mb-12">
                                            <ul className="list-unstyled widget-list">
                                                <li><a href="#" className="d-flex">NF Total Prods. <small className="ml-auto">{this.state.nfTotalProds}</small></a></li>
                                                <li><a href="#" className="d-flex">Total Quotes <small className="ml-auto">{this.state.totalQuotes}</small></a></li>
                                                <li><a href="#" className="d-flex">Unique Prod. Quoted  <small className="ml-auto">{this.state.uniqueProdQuoted}</small></a></li>
                                                <li><a href="#" className="d-flex">Prods Not Quoted <small className="ml-auto">{this.state.prodsNotQuoted}</small></a></li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 mb-12">
                                            <ul className="list-unstyled widget-list">
                                                <li><a href="#" className="d-flex">Share of Wallet Quoted <small className="ml-auto">{this.state.shareOfWalletQuoted}</small></a></li>
                                                <li><a href="#" className="d-flex">Won # <small className="ml-auto">{this.state.won}</small></a></li>
                                                <li><a href="#" className="d-flex">Win % of Quoted <small className="ml-auto">{this.state.winPercOfQuoted}</small></a></li>
                                                <li><a href="#" className="d-flex">Lost # <small className="ml-auto">{this.state.lost}</small></a></li>
                                                
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 mb-12">
                                            <ul className="list-unstyled widget-list">
                                                <li><a href="#" className="d-flex">Lost % of Quoted <small className="ml-auto">{this.state.lostPercOfQuoted}</small></a></li>
                                                <li><a href="#" className="d-flex">Pending # <small className="ml-auto">{this.state.pending}</small></a></li>
                                                <li><a href="#" className="d-flex">Pending % of Quoted <small className="ml-auto">{this.state.pendingPercOfQuoted}</small></a></li>
                                                <li><a href="#" className="d-flex"> <small className="ml-auto"></small></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <article className="card">


                                    <div className="card-body">
                                        <h3 className="h4 mb-3">{this.state.selectBrand.Name} Products</h3>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Img</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Brand Sale Price</th>
                                                <th scope="col">Status</th>

                                                <th scope="col">Peak RFQ#</th>
                                                <th scope="col">Peak QTY</th>
                                                <th scope="col">Peak Price</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {this.state.products.map((p, index )=> (<tr style={{cursor:'pointer'}}>
                                                <th onClick={(e) => this.openPage(e, p.ProductId)} scope="row">
                                                    <span>{index + 1}</span>
                                                </th>
                                                <td onClick={(e) => this.openPage(e, p.ProductId)}>
                                                    <img src={this.getImage(p.ProductImages)} className="card-img-top" style={{width:'50px'}} alt="post-thumb" />
                                                </td>
                                                <td onClick={(e) => this.openPage(e, p.ProductId)}>{p.Name}</td>
                                                <td onClick={(e) => this.openPage(e, p.ProductId)}>{p.ActualPrice}</td>
                                                <td onClick={(e) => this.openPage(e, p.ProductId)}>{p.SalePrice}</td>
                                                <td>
                                                    <ul className="card-meta-tag list-inline">
                                                        {p.Modatas.map((mo, i) => (
                                                            <li title={mo.Item} onClick={(e) => this.setValuesByMo(e, mo, p.ProductId, p.Modatas)} className="list-inline-item"><a className={(i === 0) ? 'activeStatus': ''} id={this.setElementId(mo.Id, 'activMo')} style={{marginBottom:'4px'}} href="#">{mo.Type}</a></li>
                                                        ))}
                                                    </ul>
                                                </td>
 
                                                <td onClick={(e) => this.openPage(e, p.ProductId)}><div className='modataclass' id={this.setElementId(p.ProductId, 'rfq')}> {this.getLastQuote(p.Modatas, 'RFQ')} </div></td>
                                                <td onClick={(e) => this.openPage(e, p.ProductId)}><div className='modataclass' id={this.setElementId(p.ProductId, 'qty')}> {this.getLastQuote(p.Modatas, 'Qty')} </div></td>
                                                <td onClick={(e) => this.openPage(e, p.ProductId)}><div className='modataclass' id={this.setElementId(p.ProductId, 'unitPrice')}> {this.getLastQuote(p.Modatas, 'Unit_Price')} </div></td>
                                            </tr>) )}


                                            </tbody>
                                        </table>
                                        <PaginatedItems total={this.state.nfTotalProds} onPageChange={this.paginatePage}></PaginatedItems>

                                    </div>
                                </article>
                            </div>



                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Home;
