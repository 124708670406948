import React, { Component }  from 'react';
import axios from "axios";
import Header from "./Components/Header";
import 'react-datepicker/dist/react-datepicker.css'
import PaginatedItems from "./Components/Pagination";


export class SuppliersData extends Component{
    state = {
        records: [],
        search: "",
        show: false,
        popupIndex: 0,
        filtered: false,
        pageNumber:1,
        totalItems: 0,
        showComm: false,
        suppliers:[],
        selectedOption: 0
    }

    componentDidMount() {
        let pageUrlValue = window.location.href.split('/')[4]
        if(pageUrlValue !== undefined) {
            const selectedOption = parseInt(pageUrlValue)
            this.setState({ selectedOption });
            this.getSuppliers(1, selectedOption) 
        } else {
            this.getSuppliers()
        }

    }
    
    formatDateColor(dateString) {
        if(dateString === undefined) {
            return '#000';
        }
        const [year, month, day] = dateString.split('-');
        const inputDate = new Date(year, month - 1, day, 0,0, 0);

        if (!isNaN(inputDate.getTime())) {
            const formattedDate = inputDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            if(new Date() > inputDate)
            {
                return '#f00'
            }
            else
            {
                return '#000';
            }

        }
    }

    formatDate(dateString) {
        if (dateString !== undefined && dateString.trim() !== '') {
            const [year, month, day] = dateString.split('-');
            const inputDate = new Date(year, month - 1, day, 0,0, 0);

            if (!isNaN(inputDate.getTime())) {
                const formattedDate = inputDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });
                return formattedDate;
            } else {
                return "Invalid Date";
            }
        } else {
            return "Invalid Input";
        }
    }


    handleShow(e, popupIndex) {
        this.setState({ show: true })
        this.setState({ popupIndex: popupIndex })
    };

    handleComm(e, popupIndex) {
        this.setState({ showComm: true })
        this.setState({ popupIndex: popupIndex })
    };

    formatDateTime(dateString){
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString);

            return  myDate.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

        }
    };

    getSuppliers(pageNumber = 1, selectedOption = 0) {
        let _self = this;

        let dataObj = {
            search: _self.state.search,
            order: 'desc',
            page:pageNumber,
            filterByCompany: selectedOption
        }
        
        if(_self.state.search !== "") {
            const filtered = true;
            _self.setState({ filtered });
        } else {
            const filtered = false;
            _self.setState({ filtered });
        }

        axios.put(`get-suppliers`, dataObj)
            .then(response => {
                const records = response.data.records;
                const suppliers = response.data.suppliers;
                _self.setState({ records });
                _self.setState({ suppliers });

                const totalItems = response.data.total_records
                _self.setState({ totalItems });
            }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    onSearchClick(e)
    {
        this.getSuppliers()
    }

    onCompareClick(e)
    {
        e.preventDefault();
        console.log(this.state);
        const search = this.state.search;
        if(search !== undefined) {
            window.location.href = "/compare-quotes/"+search;
        }

    }

    onSearch(e)
    {
        const search = e.target.value;
        this.setState({ search })
    }

    onKeyUp (e)
    {
        if (e.charCode === 13) {
            console.log('13');
            this.onSearchClick(e);
        }
    };

    openPage(e, SupplierDataId, SupplierDataMasterId){
        e.preventDefault();
        window.location.href = "/view/"+SupplierDataId+"/"+SupplierDataMasterId;
    }

    paginatePage = (page)=> {
        console.log(`pageNumber ! ${page}`)
        const pageNumber = page
        this.setState({ pageNumber });
        this.getSuppliers(page, this.state.selectedOption);
    }



    onContactsClick(e){
        e.preventDefault();
        window.location.href = "/suppliers-contacts";
    }

    handleSelectChange = (e) => {
        const selectedOption = parseInt(e.target.value);
        this.setState({ selectedOption });
        this.getSuppliers(1, selectedOption);
    }

    render() {
        return (
            <>
                <Header  />




                <section className="section pb-0" style={{ marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12 row noPrint" style={{padding:'13px 30px'}}>
                                <h3>Suppliers</h3>
                            </div>
                            <div className="col-lg-12 mb-12 row noPrint" >
                                <div className="input-group col-lg-7 mb-3">
                                    <button className='btn btn-warning' onClick={(e) => this.onContactsClick(e)} style={{borderRadius:'unset'}}>
                                       <i className={'ti-id-badge'}></i> Contacts
                                    </button>
                                    <select style={{marginLeft:'15px'}} value={this.state.selectedOption} onChange={this.handleSelectChange}>
                                        <option value={0}>Filer By Company</option>
                                        {this.state.suppliers.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.company}
                                            </option>
                                        ))}
                                    </select>
                                    {
                                        this.state.filtered && <button className='btn btn-success' onClick={(e) => this.onCompareClick(e)} style={{marginLeft:'15px', borderRadius:'unset'}}>
                                            <i className={'ti-search'}></i> Compare
                                        </button>
                                    }
                                    
                                </div>
                                <div className="input-group col-lg-5 mb-3">
                                    <input onKeyPress={(e) => this.onKeyUp(e)} type="text" className="form-control" onChange={(e) => this.onSearch(e)} placeholder="Ingred, SKU or Material Search" aria-label="search" style={{padding:'20px'}} aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <span onClick={(e) => this.onSearchClick(e)} className="input-group-text ti-search" id="basic-addon2"></span>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12 mb-12">
                                <table className="table table-bordered">
                                    <thead className="tableHead">
                                    <tr>
                                        <th scope="col">View</th>
                                        <th scope="col">Peak#</th>
                                        <th scope="col">Supplier Name</th>
                                        <th scope="col">Supplier Item #</th>
                                        <th scope="col">Item Description</th>
                                        <th scope="col">MOQ</th>
                                        <th scope="col">Pack Size</th>
                                        <th scope="col">U/M</th>
                                        <th scope="col"> Tier Qty 1</th>
                                        <th scope="col">$ Cost</th>
                                        <th scope="col">Good Thru Date</th>
                                        <th scope="col">Created</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {this.state.records.filter(p => p.DataType === undefined).map((p, index) => (

                                        <React.Fragment key={index}>
                                            <tr data-toggle={`collapse`} data-target={`#row${index}`}
                                                className="clickable" style={{cursor: 'pointer'}}>
                                                <td onClick={(e) => this.openPage(e, p.SupplierDataId, p.SupplierDataMasterId)}><i
                                                    className="ti-pencil-alt"></i></td>
                                                <td>{p.PeakItem}</td>
                                                <td onClick={(e) => this.openPage(e, p.SupplierDataId, p.SupplierDataMasterId)}>{p.SupplierName}</td>
                                                <td>{p.SupplierItem}</td>
                                                <td>{p.ItemDescription}</td>
                                                <td>{p.MOQ}</td>
                                                <td>{p.PackSize}</td>
                                                <td>{p.UM}</td>
                                                <td>{p.TierQty1}</td>
                                                <td>{p.TierQty1Cost}</td>
                                                <td><p style={{fontWeight: 'bold', 'color': this.formatDateColor(p.GoodThruDate)}}>{this.formatDate(p.GoodThruDate)}</p></td>
                                                <td>{this.formatDateTime(p.dateTimeCreated)}</td>
                                            </tr>
                      
                                        </React.Fragment>))}

                                    {this.state.records.filter(p => p.DataType === "PD").map((p, index) => (

                                        <React.Fragment key={index}>
                                            <tr>
                                                <td></td>
                                                <td>{p.PeakItem}</td>
                                                <td>{p.SupplierName}</td>
                                                <td>{p.SupplierItem}</td>
                                                <td>{p.ItemDescription}</td>
                                                <td>{p.MOQ}</td>
                                                <td>{p.PackSize}</td>
                                                <td>{p.UM}</td>
                                                <td>{p.TierQty1}</td>
                                                <td>{p.TierQty1Cost}</td>
                                                <td>{p.GoodThruDate}</td>
                                                <td>{p.dateTimeCreated}</td>
                                            </tr>
                 
                                        </React.Fragment>))}

                                    {
                                        this.state.records.length === 0 ? (<tr>
                                            <td colSpan="10" style={{textAlign: 'center'}}>No Records</td>
                                        </tr>) : (<tr></tr>)
                                    }


                                    </tbody>
                                </table>
                                <PaginatedItems total={this.state.totalItems}
                                                onPageChange={this.paginatePage}></PaginatedItems>

                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                </section>

                
            </>
        );
    }
}

export default SuppliersData;
