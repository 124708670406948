import React, {Component, useEffect, useState} from 'react';
import axios from "axios";
import {Routes, Route, useParams, Navigate} from 'react-router-dom';
import "yet-another-react-lightbox/styles.css";
import Header from "./Components/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QuoteHeaders from "./Data/quotes.json"


const CompareQuotes = () => {

    const [supplierData, setSupplierData] = useState(0);
    const [redirect, setRedirect] = React.useState(false);
    const [inputValue, setInputValue] = useState('');

    let { keyword } = useParams();
    useEffect(() => {

        let dataObj = {
            search: keyword,
            order: 'desc'
        }
        
        axios.put(`get-suppliers-compare`, dataObj)
            .then(response => {
                const records = response.data;
                setSupplierData(records);
            }).catch(error => {
            console.error('There was an error!', error);
        });
    }, []);

    const getFormattedDate = (dateString) => {
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString);

            return  myDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });

        }

    };

    const formatDateTime = (dateString) => {
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString);

            return  myDate.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

        }
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    
    const getQuoteIndex = (index) => {
        let color = '#EBEBEB';
        let number = (index % 2 === 0) ? 1 : 0;
        switch(number) {
            case 0:
                color = '#EBEBEB'
                break;
            case 1:
                color = '#ffffff'
                break;
            default:
                color = '#EBEBEB' 
        }
        return color;
    };

    const getQuoteContent = keyData => {
        let content = [];
        for (let i = 0; i < supplierData.length; i++) {
            let item = supplierData[i];
            if(keyData === 'GoodThruDate') {
                content.push(<td>{getFormattedDate(item[keyData])}</td>);
            } else if(keyData === 'dateTimeCreated') {
                content.push(<td>{formatDateTime(item[keyData])}</td>);
            } else {
                content.push(<td>{item[keyData]}</td>);
            }

        }
        return content;
    };

    if(redirect)
    {
        return (<Navigate to="/suppliers-data"/>)
    }
    else {
        return (
            <>
                <Header/>




                <section className="section pb-0" style={{marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12" style={{marginTop:'20px'}}>
                                <h2 className="h5 ">
                                    <a className="" href="/suppliers-data">
                                        <i className="ti-angle-left"></i> Back
                                    </a>

                                </h2>

                                <article className="card">
                                    <div className="card-body" >
                                        <div className="row">
                                            <div className="col-lg-12 mb-12" style={{overflow:"scroll"}}>
                                                <table className="table table-bordered">
                                                    <tbody>
                                                    {supplierData && QuoteHeaders.map((quote, quoteIndex )=> (
                                                       
                                                        <tr style={{background: getQuoteIndex(quoteIndex)}}>
                                                            <td className={'headerString'}>{quote.header}</td>
                                                            {getQuoteContent(quote.key)}
                                                         
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>


                                    </div>
                                </article>

                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </section>
            </>
        );
    }

}

export default CompareQuotes;
