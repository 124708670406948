import React, {Component, useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
const PaginatedItems = ({total, onPageChange}) => {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 10;
    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(total / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % total;
        setItemOffset(newOffset);
        let pageNumber = event.selected + 1;
        onPageChange(pageNumber);
    };

    return (
        <>
            <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                className={'pagination'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
                pageClassName={'page-item'}
                nextClassName={'paginate-prev-next'}
                previousClassName={'paginate-prev-next'}
            />
        </>
    );
}


export default PaginatedItems;