import React, { Component, useState }  from 'react';
import axios from "axios";
import $ from "jquery";
import Header from "./Components/Header";
import PaginatedItems from "./Components/Pagination";
import BannerDesigns from "./Components/BannerDesigns";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export class Upload extends Component{
    state = {
        selectedFile: null,
        batchFileList: []
    }

// On file select (from the pop up)
    onFileChange = event => {

        // Update the state
        this.setState({ selectedFile: event.target.files[0] });

    };

    // On file upload (click the upload button)
    onFileUpload = () => {

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "myFile",
            this.state.selectedFile,
            this.state.selectedFile.name
        );

        // Details of the uploaded file
        console.log(this.state.selectedFile);

        // Request made to the backend api
        // Send formData object
        axios.post(`/upload/file-upload`, formData).then(response => {
            window.location.reload();
        }).catch(error => {
            console.error('There was an error!', error);
        });

    };

    // File content to be displayed after
    // file upload is complete

    componentDidMount() {
        axios.get(`upload/get-files-by-batch`)
            .then(response => {
                const batchFileList = response.data.batchFileList;
                this.setState({ batchFileList });
            })
    }

    onProcessAndDownload = () => {
        console.log('process and Download')
        axios.get(`upload/process-and-download-file`)
            .then(response => {
                const element = document.createElement("a");
                element.href = axios.defaults.baseURL + 'upload/download-file';
                element.download = "download.xlsx";
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
            })

    }

    delete = (e, id) => {
        console.log(id)
        axios.get(`upload/delete/${id}`).then(response => {
            window.location.reload();
        })
    }

    fileData = () => {
        console.log(this.state.batchFileList.length)
        if (this.state.batchFileList.length > 0) {
            return (
                <button className='btn btn-success' onClick={this.onProcessAndDownload} style={{marginTop:'20px'}}>
                    <i className='ti-download'></i> Process and Download
                </button>
            );
        }
    }

    render() {
        return (
            <>
                <Header  />

                <div className="banner text-center">
                    <div className="container">
                        <div className="row" id="banner">
                            <div className="col-lg-9 mx-auto">
                                <h1 className="mb-5">RAWS BOM Upload</h1>
                            </div>
                        </div>
                    </div>
                    <BannerDesigns/>
                </div>


                <section className="section pb-0" style={{ marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12 row" >

                               
                            </div>


                            <div className="col-lg-12 mb-12">
                               


                                <article className="card">


                                    <div className="card-body">
                                        <h3>
                                            File Upload
                                        </h3>
                                        <h6>Choose an xls before pressing the Upload button</h6>
                                        <br/>
                                        <div>
                                            <input type="file" onChange={this.onFileChange} />
                                            <button className='btn btn-primary' onClick={this.onFileUpload}>
                                                <i className='ti-upload'></i> Upload!
                                            </button>
                                        </div>

                                        <ul className="list-group" style={{marginTop:'20px'}}>
                                            {this.state.batchFileList.map(dat => (
                                                <li className="list-group-item">{dat.Name} <i className='ti-trash' onClick={(e) => this.delete(e, dat.BatchFileId)} style={{float:"right", cursor: "pointer"}}></i></li>
                                            ))}
                                        </ul>
                                        {this.fileData()}
                                    </div>
                                </article>
                            </div>



                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Upload;
