import React, { Component }  from 'react';
import axios from "axios";
import $ from "jquery";
import Header from "./Components/Header";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import PhoneNumberComponent from './Components/PhoneNumberComponent';
import BannerDesigns from "./Components/BannerDesigns";
import PaginatedItems from "./Components/Pagination";

export class SupplierContacts extends Component{
    state = {
        records: [],
        search: "",
        pageNumber:1,
        totalItems: 0
    }

    getSuppliers(search, pageNumber) {
        let _self = this;
        let dataObj = {
            search: search,
            page: pageNumber
        }
        
        axios.put(`suppliers-contacts`, dataObj)
            .then(response => {
                const records = response.data.contacts;
                const totalItems = response.data.total_records;
                _self.setState({ records });
                _self.setState({ totalItems });
            }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    componentDidMount() {
        this.getSuppliers(this.state.search, this.state.pageNumber)
    }

    formatDateTime(dateString){
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString);

            return  myDate.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

        }
    };

    openPage(e, ContactId){
        e.preventDefault();
        window.location.href = "/edit-contact/"+ContactId;
    }

    onSearch(e)
    {
        this.setState({ search: e.target.value })
    }

    onKeyUp (e)
    {
        if (e.charCode === 13) {
            console.log('13');
            this.onSearchClick(e);
        }
    };

    onSearchClick(e)
    {
        this.setState({ search: e.target.value })
        this.getSuppliers(e.target.value, this.state.pageNumber)
    }

    paginatePage = (page)=> {
        console.log(`pageNumber ${page}`)
        const pageNumber = page;
        this.setState({ pageNumber });
        this.getSuppliers(this.state.search, pageNumber);
    }

    onQuotesClick(e){
        e.preventDefault();
        window.location.href = "/suppliers-data";
    }

    openQuotesPage(e, SupplierDataId){
        e.preventDefault();
        window.location.href = "/suppliers-data/"+SupplierDataId;
    }

    render() {
        return (
            <>
                <Header  />


                <section className="section pb-0" style={{ marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12 row noPrint" style={{padding: '13px 30px'}}>
                                <h3>Company</h3>
                            </div>
                            <div className="col-lg-12 mb-12 row" style={{marginTop: '10px'}}>
                                <div className="col-lg-7 mb-3">
                                    <button className='btn btn-primary' onClick={(e) => this.onQuotesClick(e)}
                                            style={{borderRadius: 'unset'}}>
                                        <i className={'ti-book'}></i> Latest Quotes
                                    </button>
                                </div>
                                <div className="input-group col-lg-5 mb-3">
                                    <input onKeyPress={(e) => this.onKeyUp(e)} type="text" className="form-control"
                                           onChange={(e) => this.onSearch(e)} placeholder="Search Company"
                                           aria-label="search" style={{padding: '20px'}}
                                           aria-describedby="basic-addon2"/>
                                    <div className="input-group-append">
                                        <span onClick={(e) => this.onSearchClick(e)}
                                              className="input-group-text ti-search" id="basic-addon2"></span>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12 mb-12">
                                <table className="table table-bordered">
                                    <thead className="tableHead">
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">CellPhone</th>
                                        <th scope="col">OfficePhone</th>
                                        <th scope="col">Created</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {this.state.records.map((p, index) => (
                                        <React.Fragment key={index}>
                                            <tr data-toggle={`collapse`} data-target={`#row${index}`}
                                                className="clickable" style={{cursor: 'pointer'}}>
                                                <td onClick={(e) => this.openQuotesPage(e, p.ContactData[0].SupplierId)}>
                                                    <i className="ti-pencil-alt"></i></td>
                                                <td>{p.company}</td>
                                                {p.ContactData[0] ? <td>{p.ContactData[0].Name}</td> :
                                                    <td></td>}
                                                {p.ContactData[0] ? <td>{p.ContactData[0].Email}</td> :
                                                    <td></td>}
                                                {p.ContactData[0] ? <td><PhoneNumberComponent
                                                    phoneNumber={p.ContactData[0].CellPhone}/></td> : <td></td>}
                                                {p.ContactData[0] ? <td><PhoneNumberComponent
                                                        phoneNumber={p.ContactData[0].OfficePhone}/></td> :
                                                    <td></td>}
                                                {p.ContactData[0] ?
                                                    <td>{this.formatDateTime(p.ContactData[0].DateTimeCreated)}</td> :
                                                    <td></td>}
                                            </tr>
                                            <tr id={`row${index}`} className="collapse">
                                                <td colSpan="11">
                                                    <div className={'row'}>
                                                        <div className="col-lg-6 mb-12">
                                                            <ul className="list-unstyled widget-list">
                                                                <li><a href="#" className="d-flex">Payment
                                                                    Terms <span
                                                                        className="ml-auto">{p.paymentTerms}</span></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">$ Credit
                                                                    Line <span
                                                                        className="ml-auto">{p.creditLine}</span></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">Shipping
                                                                    Address <span
                                                                        className="ml-auto">{p.shippingAddress}</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6 mb-12">
                                                            <ul className="list-unstyled widget-list">
                                                                <li><a href="#" className="d-flex">FOB
                                                                    Origin <span
                                                                        className="ml-auto">{p.fobOrigin}</span></a>
                                                                </li>
                                                                <li><a href="#" className="d-flex">FOB
                                                                    Destination <span
                                                                        className="ml-auto">{p.fobDest}</span></a>
                                                                </li>
                                                                <li></li>

                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <table className="table table-bordered">
                                                        <thead className="tableHead">
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">CellPhone</th>
                                                            <th scope="col">OfficePhone</th>
                                                            <th scope="col">Created</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {p.ContactData.length > 0 && p.ContactData.map((x, index) => (
                                                            <tr>
                                                                <td>{x.Name}</td>
                                                                <td>{x.Email}</td>
                                                                <td><PhoneNumberComponent
                                                                    phoneNumber={x.CellPhone}/></td>
                                                                <td><PhoneNumberComponent
                                                                    phoneNumber={x.OfficePhone}/></td>
                                                                <td>{this.formatDateTime(x.DateTimeCreated)}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>

                                                </td>
                                            </tr>
                                        </React.Fragment>))}

                                    {
                                        this.state.records.length === 0 ? (<tr>
                                            <td colSpan="10" style={{textAlign: 'center'}}>No Records</td>
                                        </tr>) : (<tr></tr>)
                                    }


                                    </tbody>
                                </table>
                                <PaginatedItems total={this.state.totalItems}
                                                onPageChange={this.paginatePage}></PaginatedItems>
                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default SupplierContacts;
