import React, { Component, useState }  from 'react';
import axios from "axios";
import $ from "jquery";
import Header from "./Components/Header";
import PaginatedItems from "./Components/Pagination";
import BannerDesigns from "./Components/BannerDesigns";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Button, Modal} from "react-bootstrap";

export class Purchases extends Component{
    state = {
        brands: [],
        products:[],
        selectBrand: {},
        fromDate : new Date(),
        toDate : new Date(),
        purchases:[],
        sources:[],
        vendor: "",
        skuType: "",
        search: "",
        totalAmount:0,
        totalQty:0,
        orderBY: 6,
        pageNumber:1,
        orderDesc:true,
        totalItems: 0,
        isPrint:false,
        printText: "Print",
        showClearFilters : false
    }


    getProducts(fromDate, toDate, vendor, skuType, search, pageNumber, orderBY, orderDesc)
    {
        let dataObj = {
            start_datetime:  fromDate,
            end_datetime: toDate,
            vendor: vendor,
            skuType: skuType,
            search: search,
            page:pageNumber,
            order:orderBY,
            orderDesc: orderDesc === true ? 1 : 0
        }
        if(fromDate !== "" && toDate !== "")
        {
            axios.put(`purchases/get-purchases`, dataObj)
                .then(response => {
                    console.log(response.data)
                    const purchases = JSON.parse(response.data.results)
                    this.setState({ purchases })

                    const totalAmount = response.data.total_amount
                    this.setState({ totalAmount });

                    const totalQty = response.data.total_qty
                    this.setState({ totalQty });
                    
                    const totalItems = response.data.total_records
                    this.setState({ totalItems });

                    const sources = response.data.sources
                    this.setState({ sources });
                }).catch(error => {
                console.error('There was an error!', error);
            });
        }

    }
    
    paginatePage = (page)=> {
        console.log(`pageNumber ${page}`)
        this.setState({ pageNumber : page });
        this.getProducts(this.state.fromDate, this.state.toDate, this.state.vendor, this.state.skuType, this.state.search, page, this.state.orderBY, this.state.orderDesc)
    }
    
    customParseFloat(amount)
    {
        if (!amount) {
            amount = "0";
        }
        return parseFloat(amount);
    }

    setFromDate(e)
    {
        console.log(e);
        const fromDate = new Date(e);
        this.setState({ fromDate });
        this.getProducts(fromDate, this.state.toDate, this.state.vendor, this.state.skuType, this.state.search, this.state.pageNumber, this.state.orderBY, this.state.orderDesc)
    }
    
    onOrderBy(e, order)
    {
        let orderDesc = true;
        if(parseInt(order) === this.state.orderBY) {
            orderDesc = !this.state.orderDesc;
            this.setState({ orderDesc });
        }
        this.setState({ orderDesc });
            
        console.log(order);
        const orderBY = order;
        this.setState({ orderBY });
        this.getProducts(this.state.fromDate, this.state.toDate, this.state.vendor, this.state.skuType, this.state.search, this.state.pageNumber, orderBY, orderDesc)
    }

    setToDate(e)
    {
        console.log(e);
        const toDate = new Date(e);
        this.setState({ toDate });
        this.getProducts(this.state.fromDate, toDate, this.state.vendor, this.state.skuType, this.state.search, this.state.pageNumber, this.state.orderBY, this.state.orderDesc)
    }

    selectSourceName(e)
    {
        const showClearFilters = true;
        this.setState({ showClearFilters })
        this.setState({ vendor: e.target.value })
        this.getProducts(this.state.fromDate, this.state.toDate, e.target.value, this.state.skuType, this.state.search, this.state.pageNumber, this.state.orderBY, this.state.orderDesc)
    }

    onSearch(e)
    {
        this.setState({ search: e.target.value })
    }
    
    appendDollarSign(money) {
        if (money !== "0" && money !== null) {
            return "$"+money;
        }
    }

    numberWithCommas(x) {
        if (!x) {
            x = "0";
        }
        x = x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        return this.appendDollarSign(x);
    }

    numberWithCommasNotDollar(x) {
        if (!x) {
            x = "0";
        }
        x = x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        return x;
    }
    
    onClearFilters() {
        let vendor = ""
        let skuType = ""
        let search = ""
        let showClearFilters = false
        this.setState({ vendor })
        this.setState({ skuType })
        this.setState({ search })
        this.setState({ showClearFilters })
        this.getProducts(this.state.fromDate, this.state.toDate, "", "", "", this.state.pageNumber, this.state.orderBY, this.state.orderDesc)
    }

    onPrint(e)
    {
        const printText = "Printing...";
        this.setState({ printText })
        
        let dataObj = {
            start_datetime:  this.state.fromDate,
            end_datetime: this.state.toDate,
            vendor: this.state.vendor,
            skuType: this.state.skuType,
            search: this.state.search,
            page:this.state.pageNumber,
            order:this.state.orderBY,
            orderDesc: this.state.orderDesc === true ? 1 : 0
        }
        if(this.state.fromDate !== "" && this.state.toDate !== "")
        {
            axios.put(`purchases/download-pdf-purchases`, dataObj)
                .then(response => {
                    console.log(response.data)
                    const isPrint = true;
                    this.setState({ isPrint })
                    
                    
                    const purchases = JSON.parse(response.data.results)
                    this.setState({ purchases })
                    

                    const totalAmount = response.data.total_amount
                    this.setState({ totalAmount });

                    const totalQty = response.data.total_qty
                    this.setState({ totalQty });

                    const totalItems = response.data.total_records
                    this.setState({ totalItems });
                    let _self = this
                    setTimeout(function() {
                        const printText = "Print";
                        _self.setState({ printText })
                        window.print(); 
                        }, 2000);
                    
                }).catch(error => {
                console.error('There was an error!', error);
            });
        }

    }


    
    onExport(e)
    {
        let dataObj = {
            start_datetime:  this.state.fromDate,
            end_datetime: this.state.toDate,
            vendor: this.state.vendor,
            skuType: this.state.skuType,
            search: this.state.search,
            page:this.state.pageNumber,
            order:this.state.orderBY,
            orderDesc: this.state.orderDesc === true ? 1 : 0
        }
        if(this.state.fromDate !== "" && this.state.toDate !== "")
        {
            axios.put(`purchases/download-purchases`, dataObj)
                .then(response => {
                    const element = document.createElement("a");
                    element.href = axios.defaults.baseURL + 'purchases/download-purchases/0';
                    element.download = "download.xlsx";
                    document.body.appendChild(element); // Required for this to work in FireFox
                    element.click();
                }).catch(error => {
                console.error('There was an error!', error);
            });
        }
    }

    handleComm(e, peakNumber) {
        console.log(peakNumber)
        const showClearFilters = true;
        this.setState({ showClearFilters })
        this.setState({ search: peakNumber })
        this.getProducts(this.state.fromDate, this.state.toDate, this.state.vendor, this.state.skuType, peakNumber, this.state.pageNumber, this.state.orderBY, this.state.orderDesc)
    };
    onSearchClick(e)
    {
        const showClearFilters = true;
        this.setState({ showClearFilters })
        this.getProducts(this.state.fromDate, this.state.toDate, this.state.vendor, this.state.skuType, this.state.search, this.state.pageNumber, this.state.orderBY, this.state.orderDesc)
    }

    selectSkuType(e)
    {
        const showClearFilters = true;
        this.setState({ showClearFilters })
        this.setState({ skuType: e.target.value })
        this.getProducts(this.state.fromDate, this.state.toDate, this.state.vendor, e.target.value, this.state.search, this.state.pageNumber, this.state.orderBY, this.state.orderDesc)
    }

    onKeyUp (e) 
    {
        if (e.charCode === 13) {
            this.onSearchClick(e);
        }
    };



    componentDidMount() {
        const fromDate = new Date("January 01, 2023 01:00:01")
        this.setState({ fromDate })
        
        this.getProducts(fromDate.toISOString(), this.state.toDate, this.state.vendor, this.state.skuType, this.state.search, this.state.pageNumber, this.state.orderBY, this.state.orderDesc)
    }

    render() {
        return (
            <>
                <Header  />


                <section className="section pb-0" style={{ marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12 row noPrint" style={{padding: '13px 30px'}}>
                                <h3>Purchases</h3>
                            </div>
                            <div className="col-lg-12 mb-12 row" style={{'@media print': {display: 'none'}}}>

                                <div className="input-group col-lg-3 mb-3">
                                    <div className="input-group-prepend" style={{height: '40px'}}>
                                        <label className="input-group-text" htmlFor="inputGroupSelect01">From</label>
                                    </div>
                                    <DatePicker selected={this.state.fromDate} class="form-control-date"
                                                onChange={(e) => this.setFromDate(e)}/>
                                </div>

                                <div className="input-group col-lg-3 mb-3">
                                    <div className="input-group-prepend" style={{height: '40px'}}>
                                        <label className="input-group-text" htmlFor="inputGroupSelect01">To</label>
                                    </div>
                                    <DatePicker selected={this.state.toDate} class="form-control-date"
                                                onChange={(e) => this.setToDate(e)}/>
                                </div>

                                <div className="input-group col-lg-3 mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text" htmlFor="inputGroupSelect01">SKU
                                            Type</label>
                                    </div>
                                    <select value={this.state.skuType} className="custom-select" id="inputGroupSelect01"
                                            onChange={(e) => this.selectSkuType(e)}>
                                        <option selected value="">All</option>
                                        <option value="Raw">Raw Materials</option>
                                        <option value="Label">Labels</option>
                                        <option value="ShippingM">Shipping Materials</option>
                                        <option value="Containers">Containers</option>
                                        <option value="Shipping">Shipping</option>
                                        <option value="Coating">Coatings</option>
                                        <option value="Freight">Transport & Misc</option>
                                    </select>
                                </div>

                                <div className="input-group col-lg-3 mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text" htmlFor="inputGroupSelect01">Source
                                            Name</label>
                                    </div>
                                    <select className="custom-select" id="inputGroupSelect01" value={this.state.vendor}
                                            onChange={(e) => this.selectSourceName(e)}>
                                        <option selected value="">All</option>
                                        {this.state.sources.map(p => (<option>{p.Source_Name}</option>))}
                                    </select>
                                </div>


                            </div>
                            <div className="col-lg-12 mb-12 row noPrint">
                                <div className="input-group col-lg-3 mb-3">
                                    {this.state.showClearFilters ?
                                        <button onClick={(e) => this.onClearFilters(e)} className="btn btn-danger"
                                                style={{zIndex: 'unset', fontSize: '12px'}}><i
                                            style={{fontWeight: 'bold'}}
                                            className="ti-na"></i> Clear Filters
                                        </button> : ''}

                                </div>
                                <div className="input-group col-lg-2 mb-3">
                                    <button onClick={(e) => this.onPrint(e)} className="btn btn-success"
                                            style={{zIndex: 'unset'}}><i style={{fontSize: '16px', fontWeight: 'bold'}}
                                                                         className="ti-printer"></i> {this.state.printText}
                                    </button>
                                </div>
                                <div className="input-group col-lg-2 mb-3">
                                    <button onClick={(e) => this.onExport(e)} className="btn btn-success"
                                            style={{zIndex: 'unset'}}><i style={{fontSize: '16px', fontWeight: 'bold'}}
                                                                         className="ti-export"></i> Export
                                    </button>
                                </div>
                                <div className="input-group col-lg-5 mb-3">
                                    <input onKeyPress={(e) => this.onKeyUp(e)} type="text" className="form-control"
                                           onChange={(e) => this.onSearch(e)}
                                           placeholder="Ingred, SKU or Material Search" aria-label="search"
                                           value={this.state.search}
                                           style={{padding: '20px'}} aria-describedby="basic-addon2"/>
                                    <div className="input-group-append">
                                        <span onClick={(e) => this.onSearchClick(e)} 
                                              className="input-group-text ti-search" id="basic-addon2"></span>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12 mb-12">
                                <table className="table table-bordered">
                                    <thead className="tableHead">
                                    <tr>
                                        <th scope="col"><span className='pointer'
                                                              onClick={(e) => this.onOrderBy(e, 1)}>Peak#</span>
                                        </th>
                                        <th scope="col"><span className='pointer'
                                                              onClick={(e) => this.onOrderBy(e, 1)}>SKU Name</span>
                                        </th>
                                        <th scope="col"><span className='pointer'
                                                              onClick={(e) => this.onOrderBy(e, 2)}>Date</span>
                                        </th>
                                        <th scope="col">Num</th>
                                        <th scope="col">Memo</th>
                                        <th scope="col"><span className='pointer'
                                                              onClick={(e) => this.onOrderBy(e, 3)}>Supplier</span>
                                        </th>
                                        <th scope="col"><span className='pointer'
                                                              onClick={(e) => this.onOrderBy(e, 4)}>Qty</span>
                                        </th>
                                        <th scope="col">U/M</th>
                                        <th scope="col"><span className='pointer'
                                                              onClick={(e) => this.onOrderBy(e, 5)}>Cost Price</span>
                                        </th>
                                        <th scope="col"><span className='pointer'
                                                              onClick={(e) => this.onOrderBy(e, 6)}>Amount</span>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <tr>
                                        <td colSpan={6}>Total</td>
                                        <td colSpan={1}>{this.numberWithCommasNotDollar(this.state.totalQty)}</td>
                                        <td colSpan={2}></td>
                                        <td colSpan={1}>{this.numberWithCommas(this.state.totalAmount)}</td>
                                    </tr>

                                    {this.state.purchases.map(p => (<tr style={{cursor: 'pointer'}}>
                                        <td><p className={(p.enrolled == true) ? 'green' : 'red'} onClick={(e) => this.handleComm(e, p.SkuNum)}>{p.SkuNum}</p></td>
                                        <td scope="row"><span>{p.SKU}</span></td>
                                        <td style={{width: '120'}}>{p.Date}</td>
                                        <td>{p.Num}</td>
                                        <td>{p.Memo}</td>
                                        <td>{p.Source_Name}</td>
                                        <td>{p.Qty}</td>
                                        <td>{p.U_M}</td>
                                        <td>{this.appendDollarSign(p.Cost_Price)}</td>
                                        <td>{this.numberWithCommas(p.Amount)}</td>
                                    </tr>))}


                                    </tbody>
                                </table>

                                {this.state.isPrint === false ? (
                                    <PaginatedItems total={this.state.totalItems}
                                                    onPageChange={this.paginatePage}></PaginatedItems>
                                ) : null}

                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </>
        );
    }
}

export default Purchases;
